export const env = {
  DEV: {
    BACK_ENV: "dev",
    API_URL: `https://api.admin.nftydoor.com/dev`,
    API_LO_URL: `https://api.lo.nftydoor.com/dev`,
    TALKJS_APP_ID: "tgga7aVE",
  },
  FRAN_CAJAL: {
    BACK_ENV: "dev-fran",
    API_URL: `https://qqlk79knaj.execute-api.us-east-1.amazonaws.com/dev-fran`,
    API_LO_URL: `https://si0ylryix7.execute-api.us-east-1.amazonaws.com/dev-fran`,
    TALKJS_APP_ID: "tgga7aVE",
  },
  DANTE: {
    BACK_ENV: "dev-dante",
    API_URL: `https://in4gtzyli7.execute-api.us-east-1.amazonaws.com/dev-dante`,
    API_LO_URL: `https://86j81z6xx6.execute-api.us-east-1.amazonaws.com/dev-dante`,
    TALKJS_APP_ID: "tgga7aVE",
  },
  ROQUE: {
    BACK_ENV: "dev-roque",
    API_URL: `https://izqn0qn7md.execute-api.us-east-1.amazonaws.com/dev-roque`,
    API_LO_URL: `https://iz0oucn123.execute-api.us-east-1.amazonaws.com/dev-roque`,
    TALKJS_APP_ID: "tgga7aVE",
  },
  LEO: {
    BACK_ENV: "dev-leo",
    API_URL: `https://aq11qbtix6.execute-api.us-east-1.amazonaws.com/dev-leo`,
    API_LO_URL: `https://8wsua8x475.execute-api.us-east-1.amazonaws.com/dev-leo`,
    TALKJS_APP_ID: "tgga7aVE",
  },
  LU: {
    BACK_ENV: "dev-lu",
    API_URL: `https://23qtqb2efa.execute-api.us-east-1.amazonaws.com/dev-lu`,
    API_LO_URL: `https://3e4txdssgg.execute-api.us-east-1.amazonaws.com/dev-lu`,
    TALKJS_APP_ID: "tgga7aVE",
  },
  JUSTO: {
    BACK_ENV: "dev-justo",
    API_URL: `https://1ic61g1ef7.execute-api.us-east-1.amazonaws.com/dev-justo`,
    API_LO_URL: `https://ewzqlp2x1a.execute-api.us-east-1.amazonaws.com/dev-justo`,
    TALKJS_APP_ID: "tgga7aVE",
  },
  LEANDRO: {
    BACK_ENV: "dev-leandro",
    API_URL: `https://opgepj5xo8.execute-api.us-east-1.amazonaws.com/dev-leandro`,
    API_LO_URL: `https://u1g8g2bhqh.execute-api.us-east-1.amazonaws.com/dev-leandro`,
    TALKJS_APP_ID: "tgga7aVE",
  },
  FRANCO: {
    BACK_ENV: "dev-franco",
    API_URL: `https://wfflrcfa0f.execute-api.us-east-1.amazonaws.com/dev-franco`,
    API_LO_URL: `https://phl8572j63.execute-api.us-east-1.amazonaws.com/dev-franco`,
    TALKJS_APP_ID: "tgga7aVE",
  },
  JOAQUIN: {
    BACK_ENV: "dev-joaquin",
    API_URL: `https://jwd6kvwg3l.execute-api.us-east-1.amazonaws.com/dev-joaquin`,
    API_LO_URL: `https://00lov29pj4.execute-api.us-east-1.amazonaws.com/dev-joaquin`,
    TALKJS_APP_ID: "tgga7aVE",
  },
  WALY: {
    BACK_ENV: "dev-waly",
    API_URL: `https://0ho2g81nue.execute-api.us-east-1.amazonaws.com/dev-waly`,
    API_LO_URL: `https://2wf4eq743k.execute-api.us-east-1.amazonaws.com/dev-waly`,
    TALKJS_APP_ID: "tgga7aVE",
  },
  ALEJO: {
    BACK_ENV: "dev-alejo",
    API_URL: `https://inl3kmgix0.execute-api.us-east-1.amazonaws.com/dev-alejo`,
    API_LO_URL: `https://kccn4cqdcl.execute-api.us-east-1.amazonaws.com/dev-alejo`,
    TALKJS_APP_ID: "tgga7aVE",
  },
  SIMON: {
    BACK_ENV: "dev-simon",
    API_URL: `https://4n17fcn6cd.execute-api.us-east-1.amazonaws.com/dev-simon`,
    API_LO_URL: `https://5slz5omj31.execute-api.us-east-1.amazonaws.com/dev-simon`,
    TALKJS_APP_ID: "tgga7aVE",
  },
  NICOLAS: {
    BACK_ENV: "dev-nicolas",
    API_URL: `https://73op81fnf7.execute-api.us-east-1.amazonaws.com/dev-nicolas`,
    API_LO_URL: `https://5pbxqdd0lk.execute-api.us-east-1.amazonaws.com/dev-nicolas`,
    TALKJS_APP_ID: "tgga7aVE",
  },
  ESTEBAN: {
    BACK_ENV: "dev-esteban",
    API_URL: `https://k1kvbqdaqd.execute-api.us-east-1.amazonaws.com/dev-esteban`,
    API_LO_URL: `https://7iksp5i5ca.execute-api.us-east-1.amazonaws.com/dev-esteban`,
    TALKJS_APP_ID: "tgga7aVE",
  },
  SHANE: {
    BACK_ENV: "dev-shane",
    API_URL: `https://lyc0rmawjf.execute-api.us-east-1.amazonaws.com/dev-shane`,
    API_LO_URL: `https://yb9wyg3fue.execute-api.us-east-1.amazonaws.com/dev-shane`,
    TALKJS_APP_ID: "tgga7aVE",
  },
  JUANI: {
    BACK_ENV: "dev-juani",
    API_URL: `https://16ufoeolme.execute-api.us-east-1.amazonaws.com/dev-juani`,
    API_LO_URL: `https://qts9ijb9yi.execute-api.us-east-1.amazonaws.com/dev-juani`,
    TALKJS_APP_ID: "tgga7aVE",
  },
  PABLOBA: {
    BACK_ENV: "dev-pabloba",
    API_URL: `https://6fbbieyfdh.execute-api.us-east-1.amazonaws.com/dev-pabloba`,
    API_LO_URL: `https://fcill65hv5.execute-api.us-east-1.amazonaws.com/dev-pabloba`,
    TALKJS_APP_ID: "tgga7aVE",
  },
  JULIAN: {
    BACK_ENV: "dev-julian",
    API_URL: `https://uthdkho2pe.execute-api.us-east-1.amazonaws.com/dev-julian`,
    API_LO_URL: `https://j1mi3bwth0.execute-api.us-east-1.amazonaws.com/dev-julian`,
    TALKJS_APP_ID: "tgga7aVE",
  },
  MELANY: {
    BACK_ENV: "dev-melany",
    API_URL: `https://c1dw35at12.execute-api.us-east-1.amazonaws.com/dev-melany`,
    API_LO_URL: `https://immo2xjuy9.execute-api.us-east-1.amazonaws.com/dev-melany`,
    TALKJS_APP_ID: "tgga7aVE",
  },
  MATIAS: {
    BACK_ENV: "dev-matias",
    API_URL: `https://3bbwwqrpxg.execute-api.us-east-1.amazonaws.com/dev-matias`,
    API_LO_URL: `https://xdlce3cp45.execute-api.us-east-1.amazonaws.com/dev-matias`,
    TALKJS_APP_ID: "tgga7aVE",
  },
  MAXI: {
    BACK_ENV: "dev-maxi",
    API_URL: `https://c6eb4b5u42.execute-api.us-east-1.amazonaws.com/dev-maxi`,
    API_LO_URL: `https://o9kxk8s7l3.execute-api.us-east-1.amazonaws.com/dev-maxi`,
    TALKJS_APP_ID: "tgga7aVE",
  },
  ARIEL: {
    BACK_ENV: "dev-ariel",
    API_URL: `https://api.admin.nftydoor.com/dev-ariel`,
    API_LO_URL: `https://api.lo.nftydoor.com/dev-ariel`,
    TALKJS_APP_ID: "tgga7aVE",
  },
  JULIO: {
    BACK_ENV: "dev-jc",
    API_URL: `https://red4tujsc8.execute-api.us-east-1.amazonaws.com/dev-jc`,
    API_LO_URL: `https://v8pa9wxy57.execute-api.us-east-1.amazonaws.com/dev-jc`,
    TALKJS_APP_ID: "tgga7aVE",
  },
  LUIS: {
    BACK_ENV: "dev-luis",
    API_URL: `https://zjjc99x9u0.execute-api.us-east-1.amazonaws.com/dev-luis`,
    API_LO_URL: `https://h13bfv8kil.execute-api.us-east-1.amazonaws.com/dev-luis`,
    TALKJS_APP_ID: "tgga7aVE",
  },
  NICOLASF: {
    BACK_ENV: "dev-nicolasf",
    API_URL: `https://jlmkw8fl36.execute-api.us-east-1.amazonaws.com/dev-nicolasf`,
    API_LO_URL: `https://z49insfbjc.execute-api.us-east-1.amazonaws.com/dev-nicolasf`,
  },
  GABRIEL: {
    BACK_ENV: "dev-gabriel",
    API_URL: `https://h7u6tpzujd.execute-api.us-east-1.amazonaws.com/dev-gabriel`,
    API_LO_URL: `https://ljwyarkz37.execute-api.us-east-1.amazonaws.com/dev-gabriel`,
  },
  PROD: {
    BACK_ENV: "prod",
    API_URL: `https://api.admin.nftydoor.com/prod`,
    API_LO_URL: `https://api.lo.nftydoor.com/prod`,
    TALKJS_APP_ID: "tgga7aVE",
  },
};

export const ENV = localStorage.getItem("currentEnvironment") ?? "DEV";

function getEnvVariable(key) {
  const isInProduction = process.env.REACT_APP_ENV === "prod";
  return env[isInProduction ? "PROD" : ENV][key];
}

export default getEnvVariable;
